/* eslint-disable no-debugger */
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React from "react";
import {
  REQUIRED,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
  SelectionField,
  TextField,
  columns,
  labels,
  statusOptions,
  dayTypeOptions,
  CONVERT_BOOL,
  CWBAgeOption,
} from "../../Components/constants/field";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getAllActiveFestivals } from "../../slices/festival/thunk";
import { useEffect } from "react";
import * as Yup from "yup";
import Loader from "../../Components/Common/Loader";
import moment from "moment";
import {
  getHotelRoomcatgeoryByHotelID,
  insertHotelRates,
} from "../../slices/thunks";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import HotelRateList from "./HotelRateList";

const HotelSpecialRate = () => {
  const history = useNavigate();
  const { ID } = useParams();

  const dispatch = useDispatch();
  const {
    hotelSpecialRates: {
      WeekDaysRateList,
      WeekEndRateList,
      SpecialDateRateList,
      form,
    },
    roomCategories: { roomCategoryList, loading: RoomCategoryLoading },
  } = useSelector((state) => state.Hotel);

  const { festivals, loading: FestivalLoading } = useSelector(
    (state) => state.Festival
  );
  useEffect(() => {
    dispatch(getAllActiveFestivals());
    let param = {
      HotelID: +ID,
      OperationType: 16,
    };
    dispatch(getHotelRoomcatgeoryByHotelID(param));
  }, []);

  function FromDate() {
    const { values, setFieldValue } = useFormikContext();
    const handleDateChange = (ev) => {
      setFieldValue("FromDate", moment(ev[0]).format(STANDARD_INPUT_DATE));
    };

    return (
      <React.Fragment>
        <Field>
          {({ field }) => (
            <Flatpickr
              name="FromDate"
              id="Rate-from-date"
              {...field}
              value={values.FromDate}
              placeholder="Select Date.."
              className="form-control"
              options={{
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d-m-Y",
                // minDate:
              }}
              onChange={handleDateChange}
            />
          )}
        </Field>
        <ErrorMessage
          name={"FromDate"}
          component="div"
          className="text-danger"
        />
      </React.Fragment>
    );
  }

  function ToDate() {
    const { values, setFieldValue } = useFormikContext();

    const handleDateChange = (ev) => {
      setFieldValue("ToDate", moment(ev[0]).format(STANDARD_INPUT_DATE));
    };

    return (
      <React.Fragment>
        <Field>
          {({ field }) => {
            return (
              <Flatpickr
                name="ToDate"
                id="to-from-date"
                {...field}
                value={values.ToDate}
                placeholder="Select Date.."
                className={classnames("form-control", {
                  // "is-invalid": Boolean(errors?.ToDate),
                })}
                options={{
                  altInput: true,
                  altFormat: "d-m-Y",
                  dateFormat: "d-m-Y",
                  minDate: values?.FromDate,
                }}
                onChange={handleDateChange}
              />
            );
          }}
        </Field>

        <ErrorMessage name={"ToDate"} component="div" className="text-danger" />
      </React.Fragment>
    );
  }

  function SpecialFields() {
    const { values } = useFormikContext();

    return (
      <React.Fragment>
        {Number(values?.DateType) === 1 ? (
          <Col lg={4}>
            <div className="mb-3">
              <Label className="form-label" htmlFor="festival">
                Festival
              </Label>
              <Field
                name="FestivalID"
                id="festival"
                options={
                  Array.isArray(festivals)
                    ? festivals.map((_f) => {
                        return {
                          value: _f.ID,
                          label: _f.Title,
                        };
                      })
                    : []
                }
                component={SelectionField}
              />
            </div>
          </Col>
        ) : null}
        <Col lg={2}>
          <div className="mb-3">
            <Label className="form-label" htmlFor="Rate-from-date">
              From
            </Label>
            <FromDate />
          </div>
        </Col>
        <Col lg={2}>
          <div className="mb-3">
            <Label className="form-label" htmlFor="to-from-date">
              To
            </Label>
            <ToDate />
          </div>
        </Col>
      </React.Fragment>
    );
  }
  const handleSave = async (e, values) => {
    let isValid = await values.validateForm();
    if (Object.keys(isValid).length) {
      return false;
    }
    let obj = {
      ...values.values,
    };
    obj.FromDate = obj?.FromDate
      ? moment(obj.FromDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "";

    obj.ToDate = obj?.ToDate
      ? moment(obj.ToDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "";
    if (Number(obj?.DateType) === 1) {
      obj.FestivalID = Number(obj?.FestivalID) ? +obj?.FestivalID : 0;
    } else {
      obj.FestivalID = 0;
    }

    obj.RoomCategoryID = +obj?.RoomCategoryID;
    obj.CWBAge = +obj?.CWBAge;

    if (obj?.DateType) {
      obj.DateType = +obj?.DateType;
    }

    // if (obj?.IsActive) {
    obj.IsActive = CONVERT_BOOL.test(obj?.IsActive);
    // }

    obj[`HotelID`] = +ID;

    if (obj.ID) {
      obj[`OperationType`] = 6;
      delete obj[`HotelName`];
      delete obj[`RoomCategoryName`];
      delete obj[`FestivalName`];
      delete obj[`Message`];
      delete obj[`Details`];
    } else {
      obj[`OperationType`] = 5;
    }
    dispatch(insertHotelRates(obj)).then((res) => {
      if (res?.payload?.Status === 200) {
        values.handleReset();
      }
      if (res?.payload?.Status === 200) {
        if (e.target.name == "save-button") {
          history("/hotels");
        }
      }
    });
  };

  let roomCategoryOptions = useMemo(() => {
    let result = [];
    if (Array.isArray(roomCategoryList)) {
      result = roomCategoryList.map((_r) => {
        return {
          value: _r.ID,
          label: _r.RoomCategoryName,
        };
      });
    }

    return result;
  }, [roomCategoryList]);

  if (FestivalLoading || RoomCategoryLoading) return <Loader />;
  return (
    <Col xl={12}>
      <Card color="dark" outline>
        <CardHeader className="bg-light">
          <h4 className="card-title mb-0">Hotel Special Rates</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              {/*  */}
              <Formik
                enableReinitialize={true}
                initialValues={form}
                validationSchema={Yup.object({
                  DateType: Yup.string().required(REQUIRED),
                  CWBAge: Yup.string().required(REQUIRED),
                  RoomCategoryID: Yup.string().required(REQUIRED),
                  FestivalID: Yup.string().when("DateType", {
                    is: "1",
                    then: Yup.string().required(REQUIRED),
                    otherwise: Yup.string(),
                  }),
                  FromDate: Yup.string().when("DateType", {
                    is: "1",
                    then: Yup.string().required(REQUIRED),
                    otherwise: Yup.string(),
                  }),
                  ToDate: Yup.string().when("DateType", {
                    is: "1",
                    then: Yup.string().required(REQUIRED),
                    otherwise: Yup.string(),
                  }),
                  KitchenCharges: Yup.string().matches(
                    /^\d+$/,
                    "Charges should not be negative"
                  ),

                  SOEPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  SOCPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  SOMAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  SOAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  DOEPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  DOCPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  DOMAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  DOAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CWBEPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CWBCPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CWBMAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CWBAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CNBEPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CNBCPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CNBMAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  CNBAPAI: Yup.string()
                    .matches(/^\d+$/, "Charges should not be negative")
                    .nullable(true)
                    .required(REQUIRED)
                    .max(6, "field must have exactly 6 digits"),
                  // EAEPAI: Yup.string()
                  //   .matches(/^\d+$/, "Charges should not be negative")
                  //   .nullable(true)
                  //   .required(REQUIRED)
                  //   .max(6, "field must have exactly 6 digits"),
                  // EACPAI: Yup.string()
                  //   .matches(/^\d+$/, "Charges should not be negative")
                  //   .nullable(true)
                  //   .required(REQUIRED)
                  //   .max(6, "field must have exactly 6 digits"),
                  // EAMAPAI: Yup.string()
                  //   .matches(/^\d+$/, "Charges should not be negative")
                  //   .nullable(true)
                  //   .required(REQUIRED)
                  //   .max(6, "field must have exactly 6 digits"),
                  // EAAPAI: Yup.string()
                  //   .matches(/^\d+$/, "Charges should not be negative")
                  //   .nullable(true)
                  //   .required(REQUIRED)
                  //   .max(6, "field must have exactly 6 digits"),
                })}
                // onSubmit={handleSubmit}
              >
                {(formik) => {
                  return (
                    <Form noValidate autoComplete="off">
                      <div>
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="day-type">
                                Day Type
                              </Label>
                              <Field
                                name="DateType"
                                id="day-type"
                                options={dayTypeOptions}
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="room-category"
                              >
                                Room Category
                              </Label>
                              <Field
                                name="RoomCategoryID"
                                id="room-category"
                                options={roomCategoryOptions}
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                          {/* Special Rates */}
                          <SpecialFields />

                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="kitchen-charges"
                              >
                                Kitchen Charges
                              </Label>
                              <Field
                                name="KitchenCharges"
                                type="number"
                                className="form-control"
                                id="kitchen-charges"
                                placeholder="Enter Kitchen Charges"
                                component={TextField}
                              />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="hotel-status-rate"
                              >
                                Status
                              </Label>
                              <Field
                                name="IsActive"
                                id="hotel-status-rate"
                                options={statusOptions}
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col lg={2}></Col>
                          {Array.isArray(labels)
                            ? labels
                                .sort((a, b) => {
                                  return a?.seq - b?.seq;
                                })
                                .map((label, i) => (
                                  <Col lg={2} key={i}>
                                    <Label className="form-label">
                                      {label?.rateType || ""}
                                    </Label>
                                  </Col>
                                ))
                            : []}
                        </Row>

                        {Array.isArray(columns) &&
                          columns.map((column) => (
                            <Row key={column.id}>
                              <Col lg={2}>
                                <div className="mb-3 d-flex  flex-row align-items-center justify-content-between">
                                  <Label className="form-label">
                                    <div className="d-flex">{column.label}</div>
                                  </Label>
                                  <div>
                                    {column?.type === "CNB" ? (
                                      <Field
                                        name={`CWBAge`}
                                        className="form-control-sm"
                                        options={CWBAgeOption}
                                        component={SelectionField}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              {Array.isArray(labels) &&
                                labels
                                  .sort((a, b) => {
                                    return a?.seq - b?.seq;
                                  })
                                  .map((rate, i) => (
                                    <Col lg={2} key={i}>
                                      <Field
                                        name={`${column?.type}${rate?.rateType}`}
                                        type="number"
                                        className="form-control"
                                        // id={`field-${row.id}`}
                                        component={TextField}
                                      />
                                    </Col>
                                  ))}
                            </Row>
                          ))}
                        <Row className="mt-2">
                          <Col lg={12}>
                            <hr></hr>
                          </Col>
                          <Col md="12" className="text-end">
                            <Button
                              className="me-2"
                              outline
                              color="primary"
                              value="Save"
                              onClick={(e) => {
                                handleSave(e, formik);
                              }}
                              name="save-button"
                              type={"submit"}
                            >
                              {" "}
                              Save{" "}
                            </Button>
                            <Button
                              color="primary"
                              name="submit-button"
                              type="submit"
                              value="SaveAndSubmit"
                              onClick={(e) => {
                                handleSave(e, formik);
                              }}
                            >
                              Save & Continue
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Col>
          </Row>
          <Row>
            {Array.isArray(WeekDaysRateList) && WeekDaysRateList.length ? (
              <HotelRateList
                Title="Hotel Rates (Weekdays)"
                List={WeekDaysRateList}
                Type="Weekdays"
                labels={labels}
                columns={columns}
              />
            ) : null}

            {Array.isArray(WeekEndRateList) && WeekEndRateList.length ? (
              <HotelRateList
                Title="Hotel Rates (Weekend)"
                List={WeekEndRateList}
                Type="Weekend"
                labels={labels}
                columns={columns}
              />
            ) : null}
            {Array.isArray(SpecialDateRateList) &&
            SpecialDateRateList.length ? (
              <HotelRateList
                Title="Special Rates"
                List={SpecialDateRateList}
                Type="SpecialRates"
                labels={labels}
                columns={columns}
              />
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default HotelSpecialRate;
